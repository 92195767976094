'use client'
import React, { useRef, useState } from 'react';
import Link from 'next/link';
import { useTranslations } from 'next-intl';

import useClickOutside from '@/hooks/useClickOutside';


interface LinksDropdownProps {
  leagueId: number;
  label: string | React.ReactNode;
}

const LinksDropdown: React.FC<LinksDropdownProps> = ({ leagueId, label }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const tFilter = useTranslations('filter');

  useClickOutside(dropdownRef, () => setIsOpen(false));

  const items: Array<{ label: string, href: string }> = [
    { label: tFilter('results'), href: `/league/${leagueId}/results` },
    { label: tFilter('goals'), href: `/league/${leagueId}/goals` },
    { label: tFilter('corners'), href: `/league/${leagueId}/corners` },
    { label: tFilter('cards'), href: `/league/${leagueId}/cards` },
    { label: tFilter('onShots'), href: `/league/${leagueId}/onShots` },
    { label: tFilter('totalShots'), href: `/league/${leagueId}/totalShots` },
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div ref={dropdownRef}>
      <p className="nav-link" onClick={toggleDropdown}>
        {label}
      </p>
      {isOpen && (
        <ul className="dropdown m-0">
          {items.map((item, index) => (
            <li className="" key={index}  onClick={() => setIsOpen(false)}>
              <Link href={item.href} className="dropdown-item">
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LinksDropdown;