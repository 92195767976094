"use client";

import React from "react";

const MultiBanner300x250: React.FC = () => {
  const script = `
      <body style="overflow: hidden; width: 100%">
        <script src="https://www.gambling-affiliation.com/cpm/v=kOI1trdhNJ1s2p0vWycOTb5pbI4.2s0rab3jZ2Afi6g_GA7331V2"></script>
      </body>
  `;

  return (
    <iframe
      className="overflow-hidden"
      title="Ad Banner"
      srcDoc={script}
      width="310"
      height="260"
      frameBorder="0"
    />
  );
};

export default MultiBanner300x250;
